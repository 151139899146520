import { ReactNode } from 'react';
import clsx from 'clsx';

export default function Hero({
  title,
  description,
  children,
  className,
  size = 'base',
}: {
  title: string | ReactNode;
  description: string | ReactNode;
  children?: ReactNode;
  className?: string;
  size?: 'base' | 'lg';
}) {
  return (
    <div
      className={clsx(
        'mx-auto max-w-screen-md text-balance px-6 text-center',
        size === 'lg' ? 'py-16 sm:py-32 xl:py-48' : 'py-12 sm:py-24',
        className,
      )}
    >
      <h1 className="mb-2 text-3xl font-medium text-contrast xl:text-4xl">
        {title}
      </h1>
      <p className="md:text-lg">{description}</p>
      {children && <div className="my-4">{children}</div>}
    </div>
  );
}
