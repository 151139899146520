import {
  IconAWS,
  IconAzure,
  IconCircleCI,
  IconDataDog,
  IconGitHub,
  IconGitLab,
  IconHoneycomb,
  IconInfluxDB,
  IconJenkins,
  IconLightstep,
  IconMixpanel,
  IconStatsd,
} from '@artilleryio/alpha-centauri-icons';
import IconOtel from '@/components/IconOtel';
import IconSlack from '@/components/IconSlack';

export const integrations = [
  {
    name: 'OpenTelemetry',
    href: 'https://www.artillery.io/docs/reference/extensions/publish-metrics/opentelemetry',
    backgroundColor: '#425cc7',
    Icon: IconOtel,
    viewBox: '0 0 24 24',
  },
  {
    name: 'GitHub Actions',
    href: 'https://www.artillery.io/docs/guides/integration-guides/github-actions',
    backgroundColor: '#24292F',
    Icon: IconGitHub,
    viewBox: '0 0 24 24',
  },
  {
    name: 'Datadog',
    href: 'https://www.artillery.io/docs/reference/extensions/publish-metrics/datadog',
    backgroundColor: '#642BAB',
    Icon: IconDataDog,
    viewBox: '0 0 24 24',
  },
  {
    name: 'Slack',
    href: 'https://www.artillery.io/docs/reference/extensions/slack',
    backgroundColor: '#4A154B',
    Icon: IconSlack,
    viewBox: '0 0 24 24',
  },
  {
    name: 'Azure DevOps',
    href: 'https://www.artillery.io/docs/guides/integration-guides/azure-devops',
    backgroundColor: '#0078d3',
    Icon: IconAzure,
    viewBox: '0 0 24 24',
  },
  {
    name: 'AWS CodeBuild',
    href: 'https://www.artillery.io/docs/guides/integration-guides/aws-codebuild',
    backgroundColor: '#222f3e',
    Icon: IconAWS,
    viewBox: '0 0 34 16',
  },
  {
    name: 'InfluxDB',
    href: 'https://www.artillery.io/docs/reference/extensions/publish-metrics/influxdb',
    backgroundColor: '#009FFB',
    Icon: IconInfluxDB,
    viewBox: '0 0 24 24',
  },
  {
    name: 'Gitlab CI/CD',
    href: 'https://www.artillery.io/docs/guides/integration-guides/gitlab-ci-cd',
    backgroundColor: '#fb6418',
    Icon: IconGitLab,
    viewBox: '0 0 24 24',
  },
  {
    name: 'CircleCI',
    href: 'https://www.artillery.io/docs/guides/integration-guides/circleci',
    backgroundColor: '#161616',
    Icon: IconCircleCI,
    viewBox: '0 0 24 24',
  },
  {
    name: 'Lightstep',
    href: 'https://www.artillery.io/docs/reference/extensions/publish-metrics/lightstep',
    backgroundColor: '#039C49',
    Icon: IconLightstep,
    viewBox: '0 0 24 24',
  },
  {
    name: 'Statsd',
    href: 'https://www.artillery.io/docs/reference/extensions/publish-metrics/statsd',
    backgroundColor: '#3db88f',
    Icon: IconStatsd,
    viewBox: '0 0 24 24',
  },
  {
    name: 'Honeycomb',
    href: 'https://www.artillery.io/docs/reference/extensions/publish-metrics/honeycomb',
    backgroundColor: '#0298EC',
    Icon: IconHoneycomb,
    viewBox: '0 0 24 24',
  },
  {
    name: 'Jenkins',
    href: 'https://www.artillery.io/docs/guides/integration-guides/jenkins',
    backgroundColor: '#d33732',
    Icon: IconJenkins,
    viewBox: '0 0 24 24',
  },
  {
    name: 'Mixpanel',
    href: 'https://www.artillery.io/docs/reference/extensions/publish-metrics/mixpanel',
    backgroundColor: '#7856FF',
    Icon: IconMixpanel,
    viewBox: '0 0 24 24',
  },
];
