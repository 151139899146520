export default function IconOtel() {
  return (
    <g
      fill="white"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M13.425 19.8001C14.5847 19.8001 15.525 18.8599 15.525 17.7001C15.525 16.5403 14.5847 15.6001 13.425 15.6001C12.2652 15.6001 11.325 16.5403 11.325 17.7001C11.325 18.8599 12.2652 19.8001 13.425 19.8001Z" />
      <path d="M5.85 18.9751L4.125 20.7001" />
      <path d="M3.07495 20.1001L4.64995 21.6751" />
      <path d="M7.2 20.3999L4.125 17.2499L8.1 13.2749L9.675 14.9249C8.55 16.1999 8.475 17.4749 8.85 18.8249L7.2 20.3999Z" />
      <path d="M11.7 13.5L9.59998 11.25L13.05 7.80005L17.025 11.775L15.45 13.35C14.25 12.6 12.825 12.825 11.7 13.5Z" />
      <path d="M19.875 11.025L13.95 5.10005C13.65 4.80005 13.65 4.35005 13.95 4.05005L15.225 2.77505C15.525 2.47505 15.975 2.47505 16.275 2.77505L22.2 8.70005C22.5 9.00005 22.5 9.45005 22.2 9.75005L20.925 11.025C20.625 11.25 20.175 11.25 19.875 11.025Z" />
    </g>
  );
}
