import { ReactNode } from 'react';
import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';

export default function Button({
  children,
  className,
  variant,
  size,
  ...props
}: LinkProps & {
  children: ReactNode;
  className?: string;
  target?: string;
  variant?: 'primary' | 'secondary';
  size?: 'sm';
}) {
  return (
    <Link
      className={clsx(
        'flex items-center gap-2 rounded border font-mono text-xs uppercase shadow-sm transition-colors',
        size === 'sm' ? 'px-1 py-0.5' : 'px-3 py-2',
        variant === 'secondary' &&
          'border-slate-4 bg-slate-3 text-slate-11 hover:border-slate-6 hover:bg-slate-5 hover:text-slate-12',
        variant === 'primary' &&
          'border-primary-4 bg-primary-3 text-primary-11 hover:border-primary-6 hover:bg-primary-5 hover:text-primary-12',
        className,
      )}
      {...props}
    >
      {children}
    </Link>
  );
}
