import { useState } from 'react';
import { IconChevronDown } from '@tabler/icons-react';

export default function Accordion({
  title,
  children,
}: {
  title?: string;
  children?: any;
  sectionId?: string;
  sectionCtrl?: string;
}) {
  const [toggle, setToggle] = useState(false);

  const showDetails = () => {
    setToggle(!toggle);
  };

  return (
    <article className="text-pretty border-t border-component first-of-type:!border-0">
      <button
        aria-expanded={toggle}
        className="group flex w-full items-center justify-between gap-4 py-4 text-left text-lg font-medium text-body transition duration-300 hover:text-contrast aria-expanded:text-contrast"
        onClick={showDetails}
      >
        {title}
        <IconChevronDown
          size={16}
          stroke={1.5}
          className="transform text-contrast transition duration-300 group-hover:text-contrast group-aria-expanded:rotate-180"
        />
      </button>
      <div
        role="region"
        aria-hidden={!toggle}
        className="prose mb-6 max-h-96 max-w-none overflow-hidden transition-all duration-300 aria-hidden:mb-0 aria-hidden:max-h-0 aria-hidden:-translate-y-1 aria-hidden:opacity-0"
      >
        {children}
      </div>
    </article>
  );
}
